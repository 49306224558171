export default {
  lastUpdated: null,
  dateRange: {
    endDate: null,
    startDate: null
  },
  dialogList: [],
  campaignList: [],
  deviceData: [],
  browserData: [],
  agentsData: [],
  routeClicks: [],
  topReferers: [],
  topOrigins: [],
  totalVisitors: 0,
  callCenterReport: {
    workingTimes: [],
    callRequests: []
  },
  vegaReports: {
    visitorsRatingsBuyingIntent: []
  },
  uniqueVisitors: 0,
  returningVisitors: 0,
  topVisitors: [],
  contactFormShownCount: 0,
  contactFormFilledCount: 0,
  contactFormBookedAppointmentCount: 0,
  generalInfo: {
    showsCount: 0,
    requestCount: 0,
    chatRequestCount: 0,
    audioRequestCount: 0,
    videoRequestCount: 0,
    vegaRequestCount: 0,
    vegaChatRequestCount: 0,
    vegaAudioRequestCount: 0,
    vegaVideoRequestCount: 0,
    sessionsCount: 0,
    chatSessionCount: 0,
    audioSessionCount: 0,
    videoSessionCount: 0,
    vegaSessionsCount: 0,
    vegaChatSessionCount: 0,
    vegaAudioSessionCount: 0,
    vegaVideoSessionCount: 0,

    sessionDuration: '00:00:00'
  },
  visitorsRating: {
    totalCount: 0,
    goodCount: 0,
    neutralCount: 0,
    supportCount: 0,
    wasteOfTimeCount: 0
  },
  campaignInfo: {
    showsCount: 0,
    requestCount: 0,
    chatRequestCount: 0,
    audioRequestCount: 0,
    videoRequestCount: 0,
    sessionsCount: 0,
    chatSessionCount: 0,
    audioSessionCount: 0,
    videoSessionCount: 0,
    sessionDuration: '00:00:00'
  },
  showsData: [],
  requestsData: [],
  sessionsData: [],

  campaignShowsData: [],
  campaignRequestsData: [],
  campaignSessionsData: [],
  endScreenFeedbackData: [],

  vegaAgentConversationRating: [],
  vegaConversationStatus: [],
  vegaVisitorAnalytics: [],
  vegaVisitorsCountPerDay: [],
  vegaFilledContactFormStatus: [],
  vegaScoresAnalytics: [],
  vegaVisitorScoreFlow: [],
  vegaCampaigns: [],
  selectedVegaUserFlow: {
    url: null,
    averageScore: null
  },
  vegaDataFetched: {
    vegaAnalytics: false
  },

  activeChartViewRangeOnAnalyticsSummary: 'week',

  isCollapsedVisitorAnalyticsBlock: true,
  isCollapsedWebsiteAnalyticsBlock: true,
  isCollapsedVisitorsJourneysBlock: true,
  isCollapsedCampaignsBlock: true,
  isCollapsedCommunicationAnalyticsBlock: true,
  isFetchedVisitorAnalyticsBlockData: false,
  isFetchedWebsiteAnalyticsBlockData: false,
  isFetchedVisitorsJourneysBlockData: false,
  isFetchedCampaignsBlockData: false,
  isFetchedCommunicationAnalyticsBlockData: false
}
